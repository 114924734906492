import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import '../theme.css';
import './header.css';

const Header = ({ siteTitle }) => (
  <header className="Header">
    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
      >
        {siteTitle}
      </Link>
    </h1>
    <h4>
      <Link
        to="/contacto"
        className="SecondaryLink"
      >
        Contacto
      </Link>
    </h4>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
