import React from 'React';
import './Footer.css';

const Footer = () => (
  <footer className="columns spaceBetween">
    <div>
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      Lab_uhardilla
      {' '}
    </div>
    <div className="columns socialContainer spaceBetween">
      <a href="https://www.instagram.com/lab_uhardilla/?hl=es"><div className="InstaLogo" /></a>
      <a href="mailto:iotagesystems@gmail.com"><div className="MailLogo" /></a>
    </div>
  </footer>
);

export default Footer;
